import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { ImportRes, CustomerResource } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  downloadFileByBlob,
  getResourceStatusOptions,
  getStatusClass,
  getStatusI18Key,
  handleImportError,
  messageError,
  showWarningConfirm,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import { ImportTemplateEnum, ResourceStatusEnum } from '@/resource/enum';
import { cloneDeep } from 'lodash-es';
import { ImportFile } from '@/views/dialogs/index';
import { ApiResponse } from '@/api/axios';
import { customerClassifyService, customerService } from '@/api';
import AddCustomer from './add-customer/add-customer.vue';
import AssignCustomer from './assign-customer/assign-customer.vue';
@Component({
  components: { ImportFile, AddCustomer, AssignCustomer }
})
export default class Customer extends Vue {
  public tableOption: OsTableOption<CustomerResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<CustomerResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'companyName',
      label: 'customer.companyName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'fullName',
      label: 'customer.fullName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    { prop: 'domainName', label: 'customer.domainName', minWidth: '150px', showOverflowTooltip: true },
    {
      prop: 'categoryName',
      label: 'customer.classify',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'customer.address',
      minWidth: '260px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `
        ${(row as CustomerResource).province}
        ${(row as CustomerResource)!.city ? (row as CustomerResource)!.city : ''}
        ${(row as CustomerResource).district ? (row as CustomerResource).district : ''}
        ${(row as CustomerResource).address}
        `;
      }
    },
    // Tips:暂不使用
    // {
    //   prop: 'servant',
    //   label: 'customer.servant',
    //   showOverflowTooltip: true,
    //   minWidth: '150px'
    // },
    {
      prop: 'paymentDay',
      label: 'customer.paymentDay',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '100px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'companyName',
      label: 'customer.companyName',
      option: {
        placeholder: 'customer.companyName'
      }
    },
    {
      type: 'Input',
      field: 'fullName',
      label: 'customer.fullName',
      option: {
        placeholder: 'customer.fullName'
      }
    },
    {
      type: 'Select',
      field: 'categoryId',
      label: 'customer.classify',
      option: {
        placeholder: 'customer.selectClassify',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'customer:save',
      handleClick: (): void => {
        this.openCustomerDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'customer.assign',
      operationType: 'assign',
      disabled: true,
      icon: 'el-icon-document',
      permissionCode: 'organize:department:customer',
      handleClick: (): void => {
        this.openAssignCustomerDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'customer:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'customer:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeleteCustomer();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'customer:editStatus',
      handleClick: (): void => {
        this.batchUpdateCustomerStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'customer:editStatus',
      handleClick: (): void => {
        this.batchUpdateCustomerStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<CustomerResource> = {
    fixed: 'right',
    width: '210px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'customer:edit',
        handleClick: (item: CustomerResource): void => {
          this.openCustomerDialog(item);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'customer:delete',
        handleClick: (item: CustomerResource): void => {
          this.deleteCustomer(item);
        }
      }
    ]
  };

  /**
   * 控制导入的dialog显示隐藏
   */
  public importDialogVisible = false;

  public importTitle = 'dialog.importCustomer';

  public editRow: CustomerResource | null = null;

  public dialogVisible = false;

  public assignCustomerVisible = false;

  public totalData = 0;

  /**
   * 导入模板的名称，用于下载导入模板
   */
  public importTemplate: ImportTemplateEnum = ImportTemplateEnum.customer;

  public classifyOptions: Array<{ label: string; value: number }> = [];

  /**
   * 更多菜单下拉的配置项
   */
  public moreMenuOptions: Array<{ label: string; routeLink: string; permissionCode: string }> = [
    // {
    //   label: 'route.department',
    //   routeLink: '/customer-department',
    //   permissionCode: ''
    // },
    // {
    //   label: 'route.position',
    //   routeLink: '/customer-position',
    //   permissionCode: ''
    // },
    // {
    //   label: 'route.role',
    //   routeLink: '/customer-role',
    //   permissionCode: ''
    // },
    // {
    //   label: 'route.personnel',
    //   routeLink: '/customer-personnel',
    //   permissionCode: ''
    // },
    {
      label: 'route.invoice',
      routeLink: '/customer-invoice',
      permissionCode: 'customer:invoice:listPage'
    },
    {
      label: 'route.sellPrice',
      routeLink: '/customer-product',
      permissionCode: 'customer:product:listPage'
    },
    {
      label: 'route.shop',
      routeLink: '/customer-shop',
      permissionCode: 'customer:shop:listPage'
    },
    {
      label: 'route.point',
      routeLink: '/customer-point',
      permissionCode: 'customer:point:listPage'
    },
    {
      label: 'route.shopTag',
      routeLink: '/shop-tag',
      permissionCode: 'customer:shopTag:listPage'
    },
    {
      label: 'route.pointTag',
      routeLink: '/point-tag',
      permissionCode: 'customer:pointTag:listPage'
    },
    {
      label: 'route.contacts',
      routeLink: '/customer-contacts',
      permissionCode: 'company:contacts:listPage'
    }
  ];

  public selectedRows: Array<CustomerResource> = [];

  private queryForm: Partial<{
    keywords: string;
    status: ResourceStatusEnum | null;
  }> = {
    keywords: '',
    status: null
  };

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public created(): void {
    this.getClassifies();
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.customerTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<CustomerResource>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: CustomerResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public importSuccess(path: string): void {
    customerService
      .importCustomer(path)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  public handleCommand(command: { routeLink: string; rowData: CustomerResource }): void {
    this.$router.push({
      path: command.routeLink,
      query: {
        companyId: command.rowData.id.toString()
      }
    });
  }

  public downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    customerService
      .downloadTemplate()
      .then((blob: any) => {
        downloadFileByBlob('customer_template.xlsx', blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }

  private openCustomerDialog(data: CustomerResource | null = null): void {
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.dialogVisible = true;
  }

  private openAssignCustomerDialog(): void {
    if (this.selectedRows.length === 0) {
      return;
    }
    this.assignCustomerVisible = true;
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<CustomerResource>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add' && x.operationType !== 'import') {
        x.disabled = value.length === 0;
      }
    });
  }

  private deleteCustomer(data: CustomerResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await customerService.delete(data.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeleteCustomer(): Promise<void> {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await customerService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private loadData(): void {
    this.tableOption.loading = true;
    customerService
      .getList(this.queryForm as CustomerResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private batchUpdateCustomerStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    customerService
      .batchUpdateCustomerStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }

  private async getClassifies(): Promise<void> {
    try {
      const classifies = await customerClassifyService.getAllUsingClassify();
      this.classifyOptions = (classifies || []).map(x => {
        return {
          label: x.categoryName,
          value: x.id
        };
      });
      const classifyQuery = this.queryItemsOption.find(x => x.field === 'categoryId');
      if (classifyQuery) {
        classifyQuery.optionData = this.classifyOptions;
      }
    } catch (error) {
      messageError(error);
    }
  }
}
