import { customerService } from '@/api';
import { OsAddress } from '@/components';
import { DialogMixin } from '@/mixins/dialog';
import { CustomerResource, PersonnelResource } from '@/resource/model';
import { convertProvinces, messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
@Component({})
export default class AddCustomer extends mixins(DialogMixin) {
  @Prop({
    required: false,
    default: () => {
      return null;
    }
  })
  public customer!: CustomerResource | null;

  @Prop({
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  public classifies!: Array<{ label: string; value: number }>;

  public customerForm: {
    companyName: string;
    fullName: string;
    remark?: string;
    domainName: string;
    categoryId: number | null;
    servant: number | null;
    provinces: Array<string>;
    address?: string;
    paymentDay?: number | null;
  } = {
    companyName: '',
    fullName: '',
    domainName: '',
    remark: '',
    categoryId: null,
    paymentDay: null,
    servant: 0,
    provinces: [],
    address: ''
  };

  public resourceFormRules = {
    companyName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customer.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          customerService
            .checkCustomerName(value, this.customer?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.customerNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    fullName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customer.inputFullName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    domainName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customer.inputDomainName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          customerService
            .checkDomainName(value, this.customer?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.domainNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    categoryId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (value !== 0 && !value) {
            callback(new Error(translation('customer.selectClassify')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    provinces: [
      {
        required: true,
        validator: (rule: any, value: Array<string>, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('customer.selectArea')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public personnelOptions: Array<PersonnelResource> = [];

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (!this.customer) {
      this.operationType = 'add';
      this.title = 'customer.addCustomer';
      return;
    }
    this.operationType = 'edit';
    this.title = 'customer.editCustomer';

    const provinces: Array<string> = [];
    if (this.customer.province) {
      provinces.push(this.customer.province);
    }
    if (this.customer.city) {
      provinces.push(this.customer.city);
    }
    if (this.customer.district) {
      provinces.push(this.customer.district);
    }
    this.customerForm.provinces = provinces.filter(item => !!item);
    (this.$refs.osAddress as OsAddress)?.reload();
    this.$nextTick(() => {
      Object.assign(this.customerForm, this.customer);
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.customerForm as Form).resetFields();
    this.customerForm.provinces = [];
  }

  public onSubmit(): void {
    (this.$refs.customerForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        const provinces = convertProvinces(this.customerForm.provinces);
        const form = { ...this.customerForm, ...provinces } as any;
        if (this.operationType === 'add') {
          await customerService.post(form);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await customerService.put(form);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
