import { departmentService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { CustomerResource, DepartmentResource, SaveAssignCustomer } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class AssignCustomer extends mixins(DialogMixin) {
  @Prop({ required: true, type: Array })
  public selectedCustomers!: Array<CustomerResource>;

  public form: Partial<SaveAssignCustomer> = {
    depId: undefined
  };

  public formRules: { [P in keyof Partial<SaveAssignCustomer>]: Array<object> } = {
    depId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('department.selectDepartment')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public departments: Array<DepartmentResource> = [];

  public get customerNames(): string {
    return this.selectedCustomers.map(x => x.companyName).join('，');
  }

  public created(): void {
    this.getDepartments();
  }

  public dialogClosed(): void {
    (this.$refs.assignForm as ElForm).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.assignForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.form.customerIdList = this.selectedCustomers.map(x => x.id);
      departmentService
        .assignCustomer(this.form as SaveAssignCustomer)
        .then(() => {
          Message.success(translation('operationRes.saveSuccess'));
          this.closeDialog();
        })
        .catch(error => {
          messageError(error);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    });
  }

  private async getDepartments(): Promise<void> {
    departmentService
      .getAllDepartments()
      .then(res => {
        this.departments = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
